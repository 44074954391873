<template>
  <div v-if="value">
    <ValidationObserver ref="form">
      <form @submit.prevent="submit">
        <div class="row">
          <div class="col-12">
            <card>
              <div class="row">
                <div class="col-md-6">
                  <ValidationProvider
                    v-slot="{ passed, failed, errors }"
                    name="ragione sociale"
                    rules="required|min:2|max:60"
                    mode="eager"
                  >
                    <base-input
                      required
                      type="text"
                      label="Ragione Sociale"
                      placeholder="Ragione Sociale"
                      :error="errors[0] || (apiErrors['business_name'] && apiErrors['business_name'][0])"
                      :class="[{ 'has-success': (passed && !apiErrors['business_name']) }, { 'has-danger': (failed || apiErrors['business_name']) }]"
                      :value="value.business_name"
                      @input="updateValue('business_name', $event)"
                    />
                  </ValidationProvider>
                </div>
                <div class="col-md-6">
                  <base-input
                    required
                    type="text"
                    label="Email"
                    placeholder="Email"
                    :value="value.email"
                    @input="updateValue('email', $event)"
                  />
                </div>
                <div class="col-md-6">
                  <ValidationProvider v-slot="{ passed, failed, errors }" name="PEC"
                                      :rules="{ required: (!value.sdi_code || value.sdi_code == '0000000'), email: true }" mode="eager">
                    <base-input
                      type="text"
                      label="PEC"
                      placeholder="PEC"
                      :value="value.pec"
                      :error="(errors[0] || apiErrors['pec'] && apiErrors['pec'][0]) ? 'Inserire un indirizzo PEC valido se non è presente il codice SDI' : ''"
                      :class="[{ 'has-success': (passed && !apiErrors['pec']) }, { 'has-danger': (failed || apiErrors['pec']) }]"
                      @input="updateValue('pec', $event)"
                    />
                  </ValidationProvider>
                </div>
                <div class="col-md-6">
                  <ValidationProvider v-slot="{ passed, failed, errors }" name="codice SDI" rules="length:7" mode="eager">
                    <base-input
                      type="text"
                      label="Codice SDI"
                      placeholder="Codice SDI"
                      :value="value.sdi_code"
                      :error="errors[0] || (apiErrors['sdi_code'] && apiErrors['sdi_code'][0])"
                      :class="[{ 'has-success': (passed && !apiErrors['sdi_code']) }, { 'has-danger': (failed || apiErrors['sdi_code']) }]"
                      @input="updateValue('sdi_code', $event)"
                    />
                  </ValidationProvider>
                </div>

                <div class="col-md-6" v-if="this.isEdit">
                    <base-input
                      double-required
                      type="text"
                      label="Partita IVA"
                      placeholder="Partita IVA"
                      :value="value.vat"
                      readonly
                    />
                </div>
                <div class="col-md-6" v-else>
                  <!-- <ValidationProvider v-slot="{ passed, failed, errors }" name="partita iva" :rules="{ pivaCF: value.fiscal_code, required: !value.fiscal_code, length: 11 }" mode="eager">-->
                  <ValidationProvider v-slot="{ passed, failed, errors }" name="partita iva"
                                      :rules="(this.isEdit) ? { required: !value.fiscal_code, length: 11 } : { pivaCF: value.fiscal_code, required: !value.fiscal_code, length: 11 }"
                                      mode="eager">
                    <base-input
                      double-required
                      type="text"
                      label="Partita IVA"
                      placeholder="Partita IVA"
                      :error="errors[0] || (apiErrors['vat'] && apiErrors['vat'][0])"
                      :class="[{ 'has-success': (passed && !apiErrors['vat']) }, { 'has-danger': (failed || apiErrors['vat']) }]"
                      :value="value.vat"
                      :readonly="isEdit"
                      @input="updateValue('vat', $event)"
                    />
                  </ValidationProvider>
                </div>

                <div class="col-md-6" v-if="this.isEdit">
                    <base-input
                      double-required
                      type="text"
                      label="Codice Fiscale"
                      placeholder="Codice Fiscale"
                      :value="value.fiscal_code"
                      readonly
                    />
                  <label>**obbligatorio inserire o la PIVA o il  CF</label>
                </div>
                <div class="col-md-6" v-else>
                  <!-- <ValidationProvider v-slot="{ passed, failed, errors }" name="codice fiscale" :rules="{ pivaCF: value.vat, required: !value.vat, length: 16 }" mode="eager">-->
                  <ValidationProvider v-slot="{ passed, failed, errors }" name="codice fiscale"
                                      :rules="(this.isEdit) ? { required: !value.vat, length: 16 } : { pivaCF: value.vat, required: !value.vat, length: 16 }"
                                      mode="eager">
                    <base-input
                      double-required
                      type="text"
                      label="Codice Fiscale"
                      placeholder="Codice Fiscale"
                      :error="errors[0] || (apiErrors['fiscal_code'] && apiErrors['fiscal_code'][0])"
                      :class="[{ 'has-success': (passed && !apiErrors['fiscal_code']) }, { 'has-danger': (failed || apiErrors['fiscal_code']) }]"
                      :value="value.fiscal_code"
                      :readonly="isEdit"
                      @input="updateValue('fiscal_code', $event)"
                    />
                  </ValidationProvider>
                  <label>**obbligatorio inserire o la PIVA o il CF</label>
                </div>

                <div class="col-md-6">
                  <ValidationProvider v-slot="{ passed, failed, errors }" name="condizioni di pagamento" rules="required" mode="eager">
                    <base-select
                      required
                      :value="value.payment_method_id"
                      size="large"
                      label="Condizioni di Pagamento"
                      :items="paymentMethods"
                      autocomplete="description"
                      item-name="description"
                      placeholder="Condizioni di Pagamento"
                      :error="errors[0] || (apiErrors['payment_method_id'] && apiErrors['payment_method_id'][0])"
                      :class="[{ 'has-success': (passed && !apiErrors['payment_method_id']) }, { 'has-danger': (failed || apiErrors['payment_method_id']) }]"
                      filterable
                      @input="updateValue('payment_method_id', $event)"
                    />
                  </ValidationProvider>
                </div>
                <div class="col-md-6">
                  <ValidationProvider v-slot="{ passed, failed, errors }" name="IBAN" mode="eager" :rules="{ required: (value.payment_method_id == 2 || value.payment_method_id == 12 || value.payment_method_id == 13), length: 27 }">
                    <base-input
                        type="text"
                        label="IBAN"
                        :value="value.notes"
                        :error="errors[0] || (apiErrors['notes'] && apiErrors['notes'][0])"
                        :class="[{ 'has-success': (passed && !apiErrors['notes']) }, { 'has-danger': (failed || apiErrors['notes']) }]"
                        placeholder="IBAN"
                        @input="updateValue('notes', $event)"
                    />
                  </ValidationProvider>
                </div>
                <div class="col-md-6" v-if="this.$isAdmin()">
                  <ValidationProvider v-slot="{ passed, failed, errors }" name="banca" rules="required" mode="eager">
                    <base-select
                      required
                      :value="value.bank_id"
                      size="large"
                      label="Banca per pagamento fatture VITE Snc"
                      :items="banks"
                      autocomplete="full_name"
                      item-name="full_name"
                      placeholder="Banca"
                      :error="errors[0] || (apiErrors['bank_id'] && apiErrors['bank_id'][0])"
                      :class="[{ 'has-success': (passed && !apiErrors['bank_id']) }, { 'has-danger': (failed || apiErrors['bank_id']) }]"
                      filterable
                      @input="updateValue('bank_id', $event)"
                    />
                  </ValidationProvider>
                </div>
                <div class="col-md-6" v-else-if="isEdit">
                  <ValidationProvider v-slot="{ passed, failed, errors }" name="banca" mode="eager" rules="required">
                    <base-input
                      required
                      disabled
                      type="text"
                      label="Banca"
                      :value="value.bank.full_name"
                      :error="errors[0] || (apiErrors['bank_id'] && apiErrors['bank_id'][0])"
                      :class="[{ 'has-success': (passed && !apiErrors['bank_id']) }, { 'has-danger': (failed || apiErrors['bank_id']) }]"
                      placeholder="Banca"
                    />
                  </ValidationProvider>
                </div>
                <div class="col-md-6" v-else-if="isEdit">
                  <ValidationProvider v-slot="{ passed, failed, errors }" name="banca" mode="eager" rules="required">
                    <base-input
                      required
                      disabled
                      type="text"
                      label="Banca"
                      :value="value.bank.full_name"
                      :error="errors[0] || (apiErrors['bank_id'] && apiErrors['bank_id'][0])"
                      :class="[{ 'has-success': (passed && !apiErrors['bank_id']) }, { 'has-danger': (failed || apiErrors['bank_id']) }]"
                      placeholder="Banca"
                    />
                  </ValidationProvider>
                </div>
                <div class="col-md-6" v-else-if="!isEdit">
                  <ValidationProvider v-slot="{ passed, failed, errors }" name="banca" mode="eager" rules="required">
                    <base-input
                      required
                      disabled
                      type="text"
                      label="Banca"
                      :value="banks[0].full_name"
                      :error="errors[0] || (apiErrors['bank_id'] && apiErrors['bank_id'][0])"
                      :class="[{ 'has-success': (passed && !apiErrors['bank_id']) }, { 'has-danger': (failed || apiErrors['bank_id']) }]"
                      placeholder="Banca"
                    />
                  </ValidationProvider>
                </div>
<!--                <div class="col-md-6">
                  <ValidationProvider v-slot="{ passed, failed, errors }" name="instradamento fattura" rules="required" mode="eager">
                    <base-select
                      required
                      :value="value.electronic_invoice_routing_id"
                      size="large"
                      label="Instradamento Fattura Elettronica"
                      :items="electronicInvoiceRoutings"
                      autocomplete="description"
                      item-name="description"
                      placeholder="Instradamento Fattura Elettronica"
                      :error="errors[0] || (apiErrors['electronic_invoice_routing_id'] && apiErrors['electronic_invoice_routing_id'][0])"
                      :class="[{ 'has-success': (passed && !apiErrors['electronic_invoice_routing_id']) }, { 'has-danger': (failed || apiErrors['electronic_invoice_routing_id']) }]"
                      filterable
                      @input="updateValue('electronic_invoice_routing_id', $event)"
                    />
                  </ValidationProvider>
                </div>-->
              </div>
            </card>
            <card>
              <div class="row">
                <div class="col-md-10">
                  <ValidationProvider v-slot="{ passed, failed, errors }" name="indirizzo" rules="required|min:2" mode="eager">
                    <base-input
                      required
                      type="text"
                      label="Indirizzo"
                      placeholder="Indirizzo"
                      :error="errors[0] || (apiErrors['address'] && apiErrors['address'][0])"
                      :class="[{ 'has-success': (passed && !apiErrors['address']) }, { 'has-danger': (failed || apiErrors['address']) }]"
                      :value="value.address"
                      @input="updateValue('address', $event)"
                    />
                  </ValidationProvider>
                </div>
                <div class="col-md-2">
                  <base-select
                    :value="value.state_id"
                    size="large"
                    label="Stato"
                    :items="states"
                    autocomplete="description"
                    item-name="description"
                    placeholder="Stato"
                    filterable
                    @input="updateValue('state_id', $event)"
                  />
                </div>
                <div :class="selectedState && selectedState.is_default ? 'col-md-5' : 'col-md-6'">
                  <ValidationProvider v-slot="{ passed, failed, errors }" name="città" rules="required|min:2" mode="eager">
                    <base-input
                      required
                      type="text"
                      label="Città"
                      placeholder="Città"
                      :error="errors[0] || (apiErrors['city'] && apiErrors['city'][0])"
                      :class="[{ 'has-success': (passed && !apiErrors['city']) }, { 'has-danger': (failed || apiErrors['city']) }]"
                      :value="value.city"
                      @input="updateValue('city', $event)"
                    />
                  </ValidationProvider>
                </div>
                <div :class="selectedState && selectedState.is_default ? 'col-md-5' : 'col-md-6'">
                  <ValidationProvider v-slot="{ passed, failed, errors }" name="cap" rules="required|length:5" mode="eager">
                    <base-input
                      required
                      type="text"
                      label="Cap"
                      placeholder="Cap"
                      :error="errors[0] || (apiErrors['cap'] && apiErrors['cap'][0])"
                      :class="[{ 'has-success': (passed && !apiErrors['cap']) }, { 'has-danger': (failed || apiErrors['cap']) }]"
                      :value="value.cap"
                      @input="updateValue('cap', $event)"
                    />
                  </ValidationProvider>
                </div>
                <div v-if="selectedState && selectedState.is_default" class="col-md-2">
                  <ValidationProvider v-slot="{ passed, failed, errors }" name="provincia" rules="required" mode="eager">
                    <base-select
                      required
                      :value="value.province_id"
                      size="large"
                      label="Provincia"
                      :items="provinces"
                      autocomplete="code"
                      item-name="code"
                      placeholder="Provincia"
                      :error="errors[0] || (apiErrors['province_id'] && apiErrors['province_id'][0])"
                      :class="[{ 'has-success': (passed && !apiErrors['province_id']) }, { 'has-danger': (failed || apiErrors['province_id']) }]"
                      filterable
                      @input="updateValue('province_id', $event)"
                    />
                  </ValidationProvider>
                </div>
              </div>
            </card>

            <card>
              <div class="row">
                <div class="col-12 d-flex justify-content-between">
                  <label>Indirizzi di Spedizione</label>
                  <div class="cursor-pointer" @click="addCustomerAddresses">
                    <mdicon name="plus" />
                  </div>
                </div>
              </div>
            </card>

            <card v-for="(data, index) in value.customer_addresses" :key="index">
              <div clas="row">
                <div class="col-12 d-flex justify-content-between mb-4">
                  <label>
                    Indirizzo di Spedizione {{ index + 1 }}
                  </label>
                  <div v-if="!data.id" class="cursor-pointer" @click="removeCustomerAddresses(index)">
                    <mdicon name="delete" />
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6">
                  <ValidationProvider v-slot="{ passed, failed, errors }" name="descrizione" rules="required|max:40" mode="eager">
                    <base-input
                      required
                      label="Descrizione"
                      placeholder="Descrizione"
                      :error="errors[0]"
                      :class="[{ 'has-success': (passed) }, { 'has-danger': (failed) }]"
                      :value="data.description"
                      @input="updateValue(`customer_addresses[${index}].description`, $event)"
                    />
                  </ValidationProvider>
                </div>
                <div class="col-md-6">
                  <ValidationProvider v-slot="{ passed, failed, errors }" name="indirizzo" rules="required|min:2" mode="eager">
                    <base-input
                      required
                      type="text"
                      label="Indirizzo"
                      placeholder="Indirizzo"
                      :error="errors[0]"
                      :class="[{ 'has-success': (passed), 'has-danger': (failed) }]"
                      :value="data.address"
                      @input="updateValue(`customer_addresses[${index}].address`, $event)"
                    />
                  </ValidationProvider>
                </div>
                <div class="col-md-6">
                  <ValidationProvider v-slot="{ passed, failed, errors }" name="città" rules="required|min:2" mode="eager">
                    <base-input
                      required
                      type="text"
                      label="Città"
                      placeholder="Città"
                      :error="errors[0]"
                      :class="[{ 'has-success': (passed), 'has-danger': (failed) }]"
                      :value="data.city"
                      @input="updateValue(`customer_addresses[${index}].city`, $event)"
                    />
                  </ValidationProvider>
                </div>
                <div class="col-md-6">
                  <ValidationProvider v-slot="{ passed, failed, errors }" name="provincia" rules="required" mode="eager">
                  <base-select
                    required
                    :value="data.province_id"
                    size="large"
                    label="Provincia"
                    :items="provinces"
                    autocomplete="code"
                    item-name="code"
                    placeholder="Provincia"
                    filterable
                    :class="[{ 'has-success': (passed), 'has-danger': (failed) }]"
                    @input="updateValue(`customer_addresses[${index}].province_id`, $event)"
                    :error="errors[0]"
                  />
                  </ValidationProvider>
                </div>
                <div class="col-md-6">
                  <ValidationProvider v-slot="{ passed, failed, errors }" name="cap" rules="required|length:5" mode="eager">
                  <base-input
                    required
                    type="text"
                    label="Cap"
                    placeholder="Cap"
                    :value="data.cap"
                    :class="[{ 'has-success': (passed), 'has-danger': (failed) }]"
                    @input="updateValue(`customer_addresses[${index}].cap`, $event)"
                    :error="errors[0]"
                  />
                  </ValidationProvider>
                </div>
                <div class="col-md-6">
                  <ValidationProvider v-slot="{ passed, failed, errors }" name="email" rules="required" mode="eager">
                  <base-input
                    required
                    type="text"
                    label="Email"
                    placeholder="Email"
                    :value="data.email"
                    @input="updateValue(`customer_addresses[${index}].email`, $event)"
                    :error="errors[0]"
                    :class="[{ 'has-success': (passed), 'has-danger': (failed) }]"
                  />
                  </ValidationProvider>
                </div>
                <div class="col-md-6">
                  <ValidationProvider
                        v-slot="{ passed, failed, errors }"
                        name="telefono"
                        :rules="{ required: (notesData[index].deliveryMode == 2 || notesData[index].deliveryMode == 3) }"
                        mode="eager">
                    <base-input
                      type="text"
                      label="Telefono"
                      placeholder="Telefono"
                      :error="errors[0]"
                      :class="[{ 'has-success': (passed) }, { 'has-danger': (failed) }]"
                      :value="data.telephone"
                      @input="updateValue(`customer_addresses[${index}].telephone`, $event)"
                    />
                  </ValidationProvider>
                </div>
<!--                <div class="col-md-6">
                  <ValidationProvider v-slot="{ passed, failed, errors }" name="cellulare" :rules="{ required: (notesData[index].selectedDeliveryTypes.indexOf('Appuntamento') >= 0 && !value.customer_addresses[index].telephone) }" 
                    mode="eager">
                    <base-input
                      type="text"
                      label="Cellulare"
                      placeholder="Cellulare"
                      :error="errors[0]"
                      :class="[{ 'has-success': (passed) }, { 'has-danger': (failed) }]"
                      :value="data.cellphone"
                      @input="updateValue(`customer_addresses[${index}].cellphone`, $event)"
                    />
                  </ValidationProvider>
                </div>-->
              </div>

              <div class="row" v-if="notesData[index]">
                <div class="col-md-12 mt-4">
                  <ValidationProvider v-slot="{ passed, failed, errors }" name="Modalità di consegna" rules="required" mode="eager">
                    <base-select
                      required
                      :value="notesData[index].deliveryMode"
                      label="Modalità di consegna"
                      :items="deliveryModes"
                      item-name="name"
                      placeholder="Modalità di consegna"
                      @input="updateDeliveryMode(index, $event)"
                      :class="[{ 'has-success': (passed), 'has-danger': (failed) }]"
                      :error="errors[0]"
                    />
                  </ValidationProvider>
                </div>
                <div class="col-md-12 mt-4" v-if="notesData[index].deliveryMode == 1 || notesData[index].deliveryMode == 2">
                  <div class="row">
                    <div class="col-md-2">
                      <label>Orario di consegna</label>
                    </div>
                    <div class="col-6 col-md-2">
                      <base-select v-model="notesData[index].from1" size="small" label="dalle" :items="hoursArray" placeholder="dalle"
                        flat />
                    </div>
                    <div class="col-6 col-md-2">
                      <base-select v-model="notesData[index].to1" size="small" label="alle" :items="hoursArray" placeholder="alle" flat />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-2">
                    </div>
                    <div class="col-6 col-md-2">
                      <base-select v-model="notesData[index].from2" size="small" label="dalle" :items="hoursArray" placeholder="dalle"
                        flat />
                    </div>
                    <div class="col-6 col-md-2">
                      <base-select v-model="notesData[index].to2" size="small" label="alle" :items="hoursArray" placeholder="alle" flat />
                    </div>
                  </div>
                </div>
                <div class="col-md-12 mt-4 mb-3" v-if="notesData[index].deliveryMode == 1 || notesData[index].deliveryMode == 2">
                  <div class="row">
                    <div class="col-md-2"><label class="mr-3">Giorni di chiusura</label></div>
                    <div class="col-md-10"><base-checkbox v-for="day in days" v-model="notesData[index].selectedDays" :value="day"
                        inline>{{ day.code }}</base-checkbox></div>
                  </div>
                </div>
<!--                <div class="col-md-12 mt-3">
                  <div class="row">
                    <div class="col-md-2"><label>Note di Consegna</label></div>
                    <div class="col-md-10">
                      <div class="row">
                        <div v-for="deliveryType in deliveryTypes" class="col-md-12">
                          <base-checkbox v-model="notesData[index].selectedDeliveryTypes" :value="deliveryType">{{ deliveryType
                          }}</base-checkbox>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>-->
              </div>
            </card>
          </div>
        </div>

        <card>
          <div class="row">
            <div class="col-12 d-flex justify-content-end">
              <base-button :loading="isLoading" native-type="submit" type="primary" class="btn-fill">
                Salva
              </base-button>
            </div>
          </div>
        </card>
      </form>
    </validationobserver>
  </div>
</template>

<script>
import { extend } from 'vee-validate'
import { required, min, max, email, length } from 'vee-validate/dist/rules'
import swal from 'sweetalert2'

import FormUpdaterMixin from 'src/mixins/FormUpdaterMixin.js'

extend('required', required)
extend('min', min)
extend('max', max)
extend('email', email)
extend('length', length)
extend('pivaCF', {
  params: ['target'],
  validate(value, { target }) {
    return (!value != !target) // this is actually a XOR operator
  },
  message: 'Compilare o la partita iva o il codice fiscale, non entrambi.'
})

export default {
  mixins: [
    FormUpdaterMixin
  ],
  props: {
    value: {
      type: Object,
      default: () => {
        return {
          business_name: null,
          email: null,
          client_code: null,
          pec: null,
          vat: null,
          fiscal_code: null,
          address: null,
          city: null,
          cap: null,
          state_id: null,
          province_id: null,
          payment_method_id: null,
          customer_addresses: [],
          notes: null,
          bank_id: null
        }
      }
    },
    paymentMethods: {
      type: Array,
      default: () => {
        return []
      }
    },
    states: {
      type: Array,
      default: () => {
        return []
      }
    },
    provinces: {
      type: Array,
      default: () => {
        return []
      }
    },
    banks: {
      type: Array,
      default: () => {
        return []
      }
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    apiErrors: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      isLoaded: false,
      days: [
        { code: 'L', value: 'Lunedì' },
        { code: 'Ma', value: 'Martedì' },
        { code: 'Me', value: 'Mercoledì' },
        { code: 'G', value: 'Giovedì' },
        { code: 'V', value: 'Venerdì' }
      ],
      deliveryTypes: [
        /*'Scarico in Cantina',
        'Consegna al piano',
        'Facchinaggio',
        'Centro storico',*/
        'Appuntamento'
      ],
      notesData: [],
      deliveryModes: [
          { id: 1, name: 'orario di consegna + giorni di chiusura'},
          { id: 2, name: 'orario di consegna + giorni di chiusura + preavviso telefonico'},
          { id: 3, name: 'appuntamento'}
      ]
    }
  },
  computed: {
    isEdit () {
      return this.value.id != null
    },
    selectedState () {
      if (this.states.length && this.value.state_id) {
        return this.states.find(state => state.id == this.value.state_id)
      } else {
        return false
      }
    },
    hoursArray() {
      var hrs = [];
      for (var i = 16; i <= 37; i++) {
        var n = i % 2 == 0 ? i / 2 + '.00' : (i + 1) / 2 - 1 + '.30';
        if (n < 10) {
          n = '0' + n;
        }
        hrs.push(n);
      }
      return hrs;
    }
  },
  watch: {
    states: {
      handler () {
        if (this.isLoaded && !this.value.state_id && this.states.length) {
          const defaultState = this.states.find(state => state.is_default)
          const stateId = defaultState ? defaultState.id : null
          this.updateValue('state_id', stateId)
        }
      }
    },
    value: {
      handler(val) {
        if(!this.isLoaded && this.isEdit) {
          val.customer_addresses.forEach(address => {
            if(address.notes_data) {
              this.notesData.push(JSON.parse(address.notes_data))
            } else {
              this.notesData.push({
                from1: null,
                to1: null,
                from2: null,
                to2: null,
                selectedDays: [],
                selectedDeliveryTypes: [],
                deliveryMode: null
              })
            }
          })
          this.isLoaded = true
        }
      }
    },
    notesData: {
      handler(val) {
        val.forEach((data, index) => {
          // this.updateValue(`customer_addresses[${index}].notes_data`, data)
          this.value.customer_addresses[index].notes_data = data
        })
      },
      deep: true
    }
  },
  methods: {
    addCustomerAddresses () {
      const customerAddresses = [...this.value.customer_addresses]
      customerAddresses.push({})
      this.notesData.push({
        from1: null,
        to1: null,
        from2: null,
        to2: null,
        selectedDays: [],
        selectedDeliveryTypes: [],
        deliveryMode: null
      })
      this.updateValue('customer_addresses', customerAddresses)
    },
    removeCustomerAddresses (index) {
      const customerAddresses = [...this.value.customer_addresses]
      customerAddresses.splice(index, 1)
      this.notesData.splice(index, 1)
      this.updateValue('customer_addresses', customerAddresses)
    },
    submit () {
      this.$refs.form.validate().then((success) => {
        if (success) {
          this.$emit('submit')
        } else {
          this.$notify({
            message: 'Sono presenti errori di validazione',
            timeout: 5000,
            horizontalAlign: 'right',
            verticalAlign: 'top',
            type: 'danger'
          })
        }
      })
    },
    handleDelete () {
      swal.fire({
        title: 'Sei sicuro?',
        text: `L'eliminazione di "${this.value.name}" sarà irreversibile.`,
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-success btn-fill',
          cancelButton: 'btn btn-danger btn-fill'
        },
        confirmButtonText: 'Si, cancella',
        cancelButtonText: 'No, annulla',
        buttonsStyling: false
      }).then((result) => {
        if (result.value) {
          this.$emit('delete')
        }
      })
    },
    updateDeliveryMode(index, deliveryMode) {
        this.value.customer_addresses[index].notes_data.deliveryMode = deliveryMode

        if (deliveryMode == 3) {
            this.value.customer_addresses[index].notes_data.selectedDays = []
            this.value.customer_addresses[index].notes_data.from1 = null
            this.value.customer_addresses[index].notes_data.from2 = null
            this.value.customer_addresses[index].notes_data.to1 = null
            this.value.customer_addresses[index].notes_data.to2 = null
        }

        this.notesData[index].deliveryMode = deliveryMode
        this.updateValue('customer_addresses', this.value.customer_addresses)
    }
  }
}
</script>
