<template>
  <div class="row">
    <div class="col-12">
      <card>
        <page-header title="NUOVA NEWS" :loading="isLoading" @backClick="$router.back()" />
      </card>
      <news-form
        v-model="news"
        :api-errors="apiErrors"
        :is-loading="isLoading"
        :files="files"
        :deleted-attachments="deletedAttachments"
        @submit="handleSubmit"
        @input="updateValue($event)"
        @clearErrors="apiErrors = {}"
        @fileUpdate="updateFiles"
      />
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import NewsForm from './Form.vue'

export default {
  components: { NewsForm },

  data () {
    return {
      news: {
        title: null,
        body: null,
        date: null,
        active: null
      },
      isLoading: false,
      apiErrors: {},
      files: [
        { fileNum: 0, attachmentType: 'news_attachment', attachmentId: null },
        { fileNum: 1, attachmentType: 'news_attachment', attachmentId: null },
        { fileNum: 2, attachmentType: 'news_attachment', attachmentId: null },
        { fileNum: 3, attachmentType: 'news_image', attachmentId: null }
      ],
      deletedAttachments: []
    }
  },

  computed: {
    ...mapGetters({
    })
  },

  created () {
  },

  beforeDestroy () {
  },

  methods: {
    ...mapActions({
      createNews: 'news/createNews',
      updateValue: 'news/updateValue'
    }),
    handleSubmit (formData) {
      this.isLoading = true
      this.createNews({ payload: formData }).then((res) => {
        this.isLoading = false
        this.$notify({
          message: 'News creata con successo.',
          timeout: 5000,
          icon: '',
          horizontalAlign: 'right',
          verticalAlign: 'top',
          type: 'success'
        })
        this.$router.push('/news')
      }).catch((err) => {
        this.isLoading = false
        if (err.response.status === 422) {
          this.apiErrors = err.response.data.content.message
        }
      })
    },
    updateFiles(newFiles) {
      this.files = newFiles; // Aggiorna i file quando vengono modificati nel form
    }
  }
}
</script>
<style>

</style>
