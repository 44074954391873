<template>
  <div v-if="value">
    <ValidationObserver ref="form">
      <form @submit.prevent="submit" enctype="multipart/form-data">
        <div class="row">
          <div class="col-12">
            <card>
              <div class="row">
                <div class="col-md-12">
                  <ValidationProvider
                    v-slot="{ passed, failed, errors }"
                    name="title"
                    rules="required|min:2|max:100"
                    mode="eager"
                  >
                    <base-input
                      required
                      type="text"
                      label="Titolo"
                      placeholder="Titolo"
                      :error="errors[0] || (apiErrors['title'] && apiErrors['title'][0])"
                      :class="[{ 'has-success': (passed && !apiErrors['title']) }, { 'has-danger': (failed || apiErrors['title']) }]"
                      :value="value.title"
                      @input="updateValue('title', $event)"
                    />
                  </ValidationProvider>
                </div>
                <div class="col-md-12">
                  <ValidationProvider v-slot="{ passed, failed, errors }"
                                      name="body"
                                      rules="required|min:2|max:10000"
                                      mode="eager"
                  >
                    <text-editor
                      type="textarea"
                      label="Testo"
                      placeholder="Testo"
                      :value="value.body"
                      height="400px"
                      :error="errors[0] || (apiErrors['body'] && apiErrors['body'][0])"
                      :class="[{ 'has-success': (passed && !apiErrors['body']) }, { 'has-danger': (failed || apiErrors['body']) }]"
                      @input="updateValue('body', $event)"
                    />
                  </ValidationProvider>
                </div>
                <div class="col-md-12">
                  <ValidationProvider
                    v-slot="{ passed, failed, errors }"
                    name="date"
                    rules="required"
                    mode="eager"
                  >
                    <base-input
                      required
                      type="text"
                      label="Data"
                      placeholder="Data"
                      :error="errors[0] || (apiErrors['date'] && apiErrors['date'][0])"
                      :class="[{ 'has-success': (passed && !apiErrors['date']) }, { 'has-danger': (failed || apiErrors['date']) }]"
                      :value="value.date"
                      @input="updateValue('date', $event)"
                    />
                  </ValidationProvider>
                </div>
                <div class="col-md-12 mt-4">
                  <base-checkbox
                    @input="updateValue('active', $event)"
                    :checked="value.active == 1"
                  >
                    Pubblicata
                  </base-checkbox>
                </div>
                <div class="col-md-12 mt-4">
                  <multiple-file-upload
                    @onFileChange="handleFileChange"
                    @onFileRemove="handleFileRemove"
                    placeholder="Document upload"
                    select-text="Select a file"
                    change-text="Change"
                    remove-text="Delete"
                    label="File name"
                    upload-name="Immagine"
                    :removable="false"
                    :list="files.filter(file => file.attachmentType === 'news_image')"
                    listKey="name"
                  ></multiple-file-upload>
                </div>
                <div class="col-md-12 mt-4">
                  <multiple-file-upload
                    @onFileChange="handleFileChange"
                    @onFileRemove="handleFileRemove"
                    placeholder="Document upload"
                    select-text="Select a file"
                    change-text="Change"
                    remove-text="Delete"
                    label="File name"
                    upload-name="Documento"
                    :removable="false"
                    :list="files.filter(file => file.attachmentType === 'news_attachment')"
                    listKey="name"
                  ></multiple-file-upload>
                </div>

              </div>
            </card>
          </div>
        </div>

        <card>
          <div class="row">
            <div class="col-12 d-flex justify-content-end">
              <base-button :loading="isLoading" native-type="submit" type="primary" class="btn-fill">
                Salva
              </base-button>
            </div>
          </div>
        </card>
      </form>
    </validationobserver>
  </div>
</template>

<script>
import { extend } from 'vee-validate'
import { required, min, max, email, length } from 'vee-validate/dist/rules'
import swal from 'sweetalert2'

import FormUpdaterMixin from 'src/mixins/FormUpdaterMixin.js'
import MultipleFileUpload from 'src/theme/components/MultipleFileUpload.vue'
import {FileUploadLine} from "../../../theme/components";

extend('required', required)
extend('min', min)
extend('max', max)
extend('length', length)

export default {
  components: {
    FileUploadLine,
    MultipleFileUpload
  },
  mixins: [
    FormUpdaterMixin
  ],
  props: {
    value: {
      type: Object,
      default: () => {
        return {
          title: null,
          body: null,
          date: null,
          active: null,
          files: null
        }
      }
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    apiErrors: {
      type: Object,
      default: () => {
        return {}
      }
    },
    files: [],
    deletedAttachments: []
  },
  data() {
    return {
      isLoaded: false
    }
  },
  created() {
    this.updateValue('date', this.getCurrentDate())
  },
  computed: {
    isEdit() {
      return this.value.id != null
    },
  },
  methods: {
    handleFileChange({$event, element}) {
      if (element.attachmentId !== undefined) {
        this.deletedAttachments.push(element.attachmentId)
      }
      const fileIndex = this.files.findIndex(file => file.fileNum === element.fileNum)

      if (fileIndex !== -1) {
        this.files.splice(fileIndex, 1, { ...this.files[fileIndex], file: $event })
      }
      this.$emit('fileUpdate', this.files)
    },
    handleFileRemove({$event, element}) {
      if (element.attachmentId !== undefined) {
        this.deletedAttachments.push(element.attachmentId)
      }

      //let thisFile = this.files.find(file => file.fileNum === element.fileNum)
      this.files.filter(file => file.fileNum !== element.fileNum)
      this.$emit('fileUpdate', this.files)
    },
    submit() {
      this.$refs.form.validate().then((success) => {
        if (success) {
          //this.$emit('submit', this.value);

          const formData = new FormData();
          // Aggiungi i campi della news
          formData.append('title', this.value.title)
          formData.append('body', this.value.body)
          formData.append('date', this.value.date)
          formData.append('active', this.value.active ? 1 : 0)
          formData.append('deletedAttachments', this.deletedAttachments)

          this.files.forEach((file, index) => {
            // Aggiungi le altre proprietà di file come JSON (escludendo la proprietà `file` che contiene il file vero e proprio)
            const fileData = {
              label: file.label,
              attachmentType: file.attachmentType,
              attachmentId: file.attachmentId
            };

            // Serializza le proprietà non-file e aggiungile al FormData
            formData.append(`fileData[${index}]`, JSON.stringify(fileData))

            // Aggiungi il vero file separatamente al FormData
            formData.append(`files[${index}]`, file.file)
          });

          // Invia il formData al parent component o al backend
          this.$emit('submit', formData)

        } else {
          this.$notify({
            message: 'Sono presenti errori di validazione',
            timeout: 5000,
            horizontalAlign: 'right',
            verticalAlign: 'top',
            type: 'danger'
          })
        }
      })
    },
    getCurrentDate() {
      const today = new Date();
      const day = String(today.getDate()).padStart(2, '0'); // ottieni il giorno e aggiungi uno 0 se è minore di 10
      const month = String(today.getMonth() + 1).padStart(2, '0'); // ottieni il mese (0-indexed) e aggiungi uno 0 se è minore di 10
      const year = today.getFullYear();

      return `${day}-${month}-${year}`; // formato 'dd-mm-yyyy'
    }
  }
}
</script>

<style>
.ck-blurred {
  height: 400px !important; /* Imposta un'altezza fissa */
  overflow-y: auto; /* Aggiungi lo scroll se necessario */
}
</style>