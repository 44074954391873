<template>
    <div>
        <div class="row d-flex justify-content-center">
            <div class="col-12">
                <FormWizard 
                    v-if="reservation"
                    v-model="reservation"
                    :total="total"
                    :total-discount="totalDiscount"
                    :total-discounted="totalDiscounted"
                    :customer-discount-percentage="customerDiscountPercentage"
                    :discount-percentage="discountPercentage"
                    :total-discounted-with-shipping="totalDiscountedWithShipping"
                    :total-discounted-with-shipping-and-iva="totalDiscountedWithShippingAndIva"
                    :total-quantity="totalQuantity"
                    :total-quantity-without-gifts-and-replacements="totalQuantityWithoutGiftsAndReplacements"
                    :total-unassigned-free-bottles="totalUnassignedFreeBottles"
                    :shipping-costs="shippingCosts"
                    :flat-total="flatTotal"
                    :flat-cart="flatCart"
                    :payment-methods="paymentMethods"
                    :shipping-methods="shippingMethods"
                    :electronic-invoice-routings="electronicInvoiceRoutings"
                    :delivery-notes="deliveryNotes"
                    :states="states"
                    :provinces="provinces"
                    :apiErrors="apiErrors"
                    :is-loading="isLoading"
                    @saveAsDraft="saveDraft"
                    @saveOrder="saveReservation"
                    @automaticSave="automaticSave"
                    @cancelOrder="cancelReservation"
                    @clearErrors="apiErrors = {}"
                />
            </div>
        </div>
    </div>
</template>
<script>
import FormWizard from './FormWizard.vue';
import { mapActions, mapGetters } from 'vuex';
import OrderWizardMixin from '@/mixins/OrderWizardMixin';
import OrderMixin from '@/mixins/OrderMixin';

import swal from 'sweetalert2'

export default {
    mixins: [OrderWizardMixin, OrderMixin],

    components: { FormWizard },

    data() {
        return {
            reservation: {
                customer: null,
                customer_address: null,
                payment_method: null,
                shipping_method: null,
                cart: [],
                notes: null,
                quotation: false,
                delivery_notes: null,
                delivery_floor: null,
                appointment_phone_number: null,
                mandatory_delivery_date: null,
                mandatory_delivery_max_date: null,
                additional_costs: null
            },
            reservationId: null,
            isLoading: false,
            apiErrors: {}
        }
    },

    watch: {
        storedReservation: {
            handler(val) {
                if(this.storedReservation.id && !this.reservation.id) {
                    this.reservation = { ...this.storedReservation }
                }
            },
            deep: true
        }
    },

    computed: {
        ...mapGetters({
            storedReservation: 'orders/single'
        })
    },

    created() {
        this.reservationId = this.$route.params.id
        this.getReservation(this.reservationId).then(res => {
            //if (this.reservation.order_status.is_persistent) {
                //this.$router.back()
            //}
        })
    },

    beforeDestroy() {
        this.clearReservation()
    },

    methods: {
        ...mapActions({
            getReservation: 'orders/get',
            updateReservation: 'orders/update',
            updateDraft: 'orders/updateDraft',
            autosave: 'orders/autosave',
            updateValue: 'orders/updateValue',
            clearReservation: 'orders/resetSingle',
        }),
        cancelReservation() {
            swal.fire({
                title: 'Sei sicuro di voler annullare le modifiche?',
                showCancelButton: true,
                customClass: {
                    confirmButton: 'btn btn-success btn-fill',
                    cancelButton: 'btn btn-danger btn-fill'
                },
                confirmButtonText: 'Si',
                cancelButtonText: 'No',
                buttonsStyling: false
            }).then((result) => {
                if (result.value) {
                    this.$router.push('/ordini')
                }
            })
        },
        automaticSave(event) {
            //this.saveDraft(event, false)
            this.isLoading = true
            this.autosave({ id: this.reservation.id, payload: this.flatOrder }).then((res) => {
                this.isLoading = false
            }).catch((err) => {
                this.isLoading = false
                if (err.response.status === 422) {
                    this.apiErrors = err.response.data.content.message
                }
            })
        },
        saveDraft(event, redirect=true) {
            this.isLoading = true
            this.updateDraft({ id: this.reservation.id, payload: this.flatOrder }).then((res) => {
                this.isLoading = false
                if (redirect) {
                    this.$notify({
                        message: 'Prenotazione (bozza) aggiornata con successo.',
                        timeout: 5000,
                        icon: '',
                        horizontalAlign: 'right',
                        verticalAlign: 'top',
                        type: 'success'
                    })
                    this.$router.push('/prenotazioni')
                }
            }).catch((err) => {
                this.isLoading = false
                if (err.response.status === 422) {
                    this.apiErrors = err.response.data.content.message
                }
            })
        },
        saveReservation() {
            this.isLoading = true
            this.updateReservation({ id: this.reservation.id, payload: this.flatOrder }).then((res) => {
                this.isLoading = false
                this.$notify({
                    message: 'Prenotazione aggiornata con successo.',
                    timeout: 5000,
                    icon: '',
                    horizontalAlign: 'right',
                    verticalAlign: 'top',
                    type: 'success'
                })
                this.$router.push('/prenotazioni')
            }).catch((err) => {
                this.isLoading = false
                if (err.response.status === 422) {
                    this.apiErrors = err.response.data.content
                }
            })
        },
    }
};
</script>
