<template>
  <div class="row" v-if="!isLoading">
    <div class="col-12">
      <card>
        <page-header :title="pageTitle" :loading="isLoading" @backClick="$router.back()" />
      </card>
      <news-form
        :value="news"
        :is-loading="isLoading"
        :files="files"
        :deleted-attachments="deletedAttachments"
        @submit="handleSubmit"
        @delete="handleDelete"
        @input="updateValue($event)"
        @clearErrors="apiErrors = {}"
        @fileUpdate="updateFiles"
      />
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import NewsForm from './Form.vue'

export default {
  components: { NewsForm },
  data () {
    return {
      newsId: null,
      isLoading: true,
      apiErrors: {},
      files: [
        { fileNum: 0, attachmentType: 'news_attachment', attachmentId: null },
        { fileNum: 1, attachmentType: 'news_attachment', attachmentId: null },
        { fileNum: 2, attachmentType: 'news_attachment', attachmentId: null },
        { fileNum: 3, attachmentType: 'news_image', attachmentId: null }
      ],
      deletedAttachments: []
    }
  },

  computed: {
    ...mapGetters({
      news: 'news/single',
    }),
    pageTitle () {
      return 'MODIFICA NEWS'
    }
  },

  created () {
    this.newsId = this.$route.params.id
    this.getNews(this.newsId).then(() => {
      this.news.attachments.forEach((attachment) => {
        for (let fileIndex = 0; fileIndex < this.files.length; fileIndex++) {
          let fileRow = this.files[fileIndex];
          if (fileRow.attachmentType == attachment.type && !fileRow.attachmentId) {
            this.files[fileIndex].attachmentId = attachment.id
            this.files[fileIndex].label = attachment.label
            this.files[fileIndex].previewText = attachment.name
            this.files[fileIndex].url = attachment.name
            break
          }
        }
        this.isLoading = false
      })
      if (this.news.attachments.length == 0) {
        this.isLoading = false
      }
    })

  },

  beforeDestroy () {
    this.clearNews()
  },

  methods: {
    ...mapActions({
      getNews: 'news/get',
      updateNews: 'news/updateNews',
      deleteNews: 'news/delete',
      clearNews: 'news/resetSingle',
      updateValue: 'news/updateValue'
    }),
    handleSubmit (formData) {
      this.isLoading = true
      this.updateNews({ id: this.newsId, payload: formData }).then((res) => {
        this.$notify({
          message: 'News aggiornata con successo.',
          timeout: 5000,
          icon: '',
          horizontalAlign: 'right',
          verticalAlign: 'top',
          type: 'success'
        })
        this.$router.push('/news')
      }).catch((err) => {
        this.isLoading = false
        if (err.response.status === 422) {
          this.apiErrors = err.response.data.content.message
        }
      })
    },
    handleDelete () {
      this.isLoading = true
      this.deleteNews(this.newsId).then((res) => {
        this.isLoading = false
        this.$notify({
          message: 'News eliminato con successo.',
          timeout: 5000,
          icon: '',
          horizontalAlign: 'right',
          verticalAlign: 'top',
          type: 'success'
        })
        this.$router.push('/news')
      })
    },
    updateFiles(newFiles) {
      this.files = newFiles; // Aggiorna i file quando vengono modificati nel form
    }
  }
}
</script>
<style>

</style>
