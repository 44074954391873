<template>
    <div>
        <list-table v-model="query" :pagination="pagination" :columns="tableColumns" :items="customers" :meta="meta"
            new-button-label="NUOVO CLIENTE"
            @onNew="createModal=true" :search-fields="searchFields" name-prop="business_name" hide-default-search
            :deletable="false" :showable="false" selectable
            @rowSelected="selectCustomer" @onEdit="editCustomer" :selected-row-value="order.customer">
        </list-table>

        <modal :show.sync="createModal" @close="createModal =false">
            <div class="row">
                <div class="col-12">
                    <customer-form v-if="banks.length" v-model="customer" :payment-methods="paymentMethods" :banks="banks"
                        :electronic-invoice-routings="electronicInvoiceRoutings" :states="states" :provinces="provinces"
                        :api-errors="apiErrors" :is-loading="isLoading" @submit="handleSubmit" @input="updateValue($event)"
                        @clearErrors="apiErrors = {}" />
                </div>
            </div>
        </modal>
    </div>
</template>

<script>
import { ListTable, Modal } from '@/theme/components/index'
import { mapActions, mapGetters } from 'vuex';
import CustomerForm from '@/pages/Dashboard/Customers/Form.vue'

export default {
    props: {
        order: {
            type: Object,
            default: () => {
                return {}
            }
        },
        paymentMethods: {
            type: Array,
            default: () => {
                return []
            }
        },
        electronicInvoiceRoutings: {
            type: Array,
            default: () => {
                return []
            }
        },
        states: {
            type: Array,
            default: () => {
                return []
            }
        },
        provinces: {
            type: Array,
            default: () => {
                return []
            }
        },
    },

    components: {
        ListTable,
        Modal, 
        CustomerForm
    },

    data() {
        return {
            createModal: false,
            apiErrors: {},
            isLoading: false,
            query: {
                rowsPerPage: 10,
                page: 1,
                sort: null,
                filter: {
                    custom_search: ''
                }
            },
            customer: {
                business_name: null,
                email: null,
                client_code: null,
                pec: null,
                discount: 0,
                vat: null,
                fiscal_code: null,
                address: null,
                city: null,
                cap: null,
                state_id: null,
                province_id: null,
                payment_method_id: null,
                electronic_invoice_routing_id: null,
                customer_addresses: [],
                notes: null,
                bank_id: 1
            },
            pagination: {
                perPageOptions: [5, 10, 25, 50],
                total: 0
            },
            searchFields: [
                {
                    type: 'input',
                    propName: 'custom_search',
                    name: 'Cerca'
                }
            ],
            tableColumns: [
                {
                    prop: 'client_code',
                    label: 'Cod.',
                    sortable: true
                },
                {
                    prop: 'business_name',
                    label: 'Nome',
                    sortable: true,
                    minWidth: 150
                },
                {
                    prop: 'city',
                    label: 'Città',
                    sortable: true,
                },
                {
                    prop: 'province',
                    label: 'Prov.',
                    sortable: true
                },
                {
                    prop: 'vat',
                    label: 'P.IVA',
                    sortable: true,
                    minWidth: 150
                }
            ]
        }
    },

    watch: {
        query: {
            handler() {
                this.fetchCustomers(this.query)
            },
            deep: true
        },
        meta: {
            handler(val) {
                this.pagination.total = val.total
            },
            deep: true
        }
    },

    created() {
        this.fetchCustomers(this.query)
        this.fetchBanks()
    },

    beforeDestroy() {
        this.clearCustomers()
        this.clearBanks()
    },

    computed: {
        ...mapGetters({
            customers: 'customers/items',
            meta: 'customers/meta',
            banks: 'banks/items'
        })
    },

    methods: {
        ...mapActions({
            fetchCustomers: 'customers/fetch',
            createCustomer: 'customers/create',
            updateCustomer: 'customers/update',
            clearCustomers: 'customers/resetItems',
            fetchBanks: 'banks/fetch',
            clearBanks: 'banks/resetItems',
            updateValue: 'customers/updateValue',
        }),
        validate() {
            return this.order.customer;
        },
        selectCustomer(customer) { 
            this.$emit('onCustomerSelection', customer)
        },
        editCustomer(customer) {
            this.customer = Object.assign({}, customer);
            this.createModal = true
        },
        resetCustomerForm() {
            this.customer = {
                business_name: null,
                email: null,
                client_code: null,
                pec: null,
                discount: 0,
                vat: null,
                fiscal_code: null,
                address: null,
                city: null,
                cap: null,
                state_id: null,
                province_id: null,
                payment_method_id: null,
                electronic_invoice_routing_id: null,
                customer_addresses: [],
                notes: null,
                bank_id: 1
            }
        },
        handleSubmit() {
            if(this.customer.id) {
                this.handleUpdate()
            } else {
                this.handleCreate()
            }
        },
        handleCreate() {
            this.isLoading = true
            this.createCustomer({ payload: this.customer }).then((res) => {
                this.isLoading = false
                this.$notify({
                    message:
                        'Cliente creato con successo.',
                    timeout: 5000,
                    icon: '',
                    horizontalAlign: 'right',
                    verticalAlign: 'top',
                    type: 'success'
                })
                this.fetchCustomers(this.query)
                this.createModal = false
                this.resetCustomer()
            }).catch((err) => {
                this.isLoading = false
                if (err.response.status === 422) {
                    this.apiErrors = err.response.data.content.message
                };
            })
        },
        handleUpdate() {
            this.isLoading = true
            this.updateCustomer({ id: this.customer.id, payload: this.customer }).then((res) => {
                this.isLoading = false
                this.$notify({
                    message:
                        'Cliente aggiornato con successo.',
                    timeout: 5000,
                    icon: '',
                    horizontalAlign: 'right',
                    verticalAlign: 'top',
                    type: 'success'
                })
                this.fetchCustomers(this.query).then(r => {
                    if (this.order.customer) {
                        let refreshedCustomer = this.customers.find(c => c.id === this.order.customer.id);
                        this.selectCustomer(refreshedCustomer)
                    }
                })
                this.createModal = false
                this.resetCustomerForm()
            }).catch((err) => {
                this.isLoading = false
                if (err.response.status === 422) {
                    this.apiErrors = err.response.data.content.message
                };
            })
        }
    }
}
</script>